import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

"use strict";

let lang = 'pl';

$(function() {
    lang = $('html').attr('lang');

    $('.modal').on('show.bs.modal', function () {
        $("body").addClass("modal-open");
        $('html').css({ 'overflow': 'hidden' });
    }).on('hide.bs.modal', function () {
        $("body").removeClass("modal-open");
        $('html').css({ 'overflow': 'auto' });
    });

    $('#modal-form-after #rule-button').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('#modal-rule').modal('show');
    });

    let stopHalfScroll = false

    $(window).scroll(function (e) {
        const body = $("body");
        const bodyHeight = body.height();
        const halfHeight = bodyHeight * 0.5;


        if ($(window).scrollTop() > (bodyHeight - halfHeight) && !stopHalfScroll) {
            stopHalfScroll = true;
            if (typeof dataLayer !== 'undefined') {
                $('body').append('' +
                    '<!--' +
                    'Fragment kodu zdarzenia (2023.11_Przelewy24_Scroll50%, https://www.przelewy24.pl/): nie usuwać.' +
                    'Umieść ten fragment kodu na stronach ze śledzonymi zdarzeniami.' +
                    'Data utworzenia: 10/31/2023' +
                    '-->' +
                    '<script>' +
                    '  gtag(\'event\', \'conversion\', {' +
                    '    \'allow_custom_scripts\': true,' +
                    '    \'u4\': \'[scroll length]\',' +
                    '    \'send_to\': \'DC-13879267/112023/20231001+standard\'' +
                    '  });' +
                    '</script>' +
                    '<noscript>' +
                    '<img src="https://ad.doubleclick.net/ddm/activity/src=13879267;type=112023;cat=20231001;u4=[scroll length];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[Random Number]?" width="1" height="1" alt=""/>' +
                    '</noscript>' +
                    '<!-- Koniec fragmentu kodu zdarzenia: nie usuwać -->' +
                    '')
            }
        }
    });

    // $('a[href="#application"]').on('click', function () {
    //     if (typeof dataLayer !== 'undefined') {
    //         $('body').append('' +
    //             '<!--' +
    //             'Fragment kodu zdarzenia (2023.11_Przelewy24_ClickButton_WezUdzial, https://www.przelewy24.pl/): nie usuwać.' +
    //             'Umieść ten fragment kodu na stronach ze śledzonymi zdarzeniami.' +
    //             'Data utworzenia: 10/31/2023' +
    //             '-->' +
    //             '<script>' +
    //             '  gtag(\'event\', \'conversion\', {' +
    //             '    \'allow_custom_scripts\': true,' +
    //             '    \'u2\': \'[linkclick]\',' +
    //             '    \'u3\': \'[clicktext]\',' +
    //             '    \'send_to\': \'DC-13879267/112023/2023100+standard\'' +
    //             '  });' +
    //             '</script>' +
    //             '<noscript>' +
    //             '<img src="https://ad.doubleclick.net/ddm/activity/src=13879267;type=112023;cat=2023100;u2=[linkclick];u3=[clicktext];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[Random Number]?" width="1" height="1" alt=""/>' +
    //             '</noscript>' +
    //             '<!-- Koniec fragmentu kodu zdarzenia: nie usuwać -->' +
    //             '')
    //     }
    // });

    $('.guaranteed-reward-checkbox').addClass('d-none');

    $('.radiobox-logo label').on('click', function () {
        if ($(this).siblings('input').is(':checked')) {
            setTimeout(() => $(this).siblings('input').prop('checked', false).trigger('change'));
        }
    });

    $('input[name="personal_data[legimi]"]').on('change', function () {
        $('#guaranteedRewardInvalidFeedback').toggleClass('active', $(this).is(':invalid'));
        if ($(this).is(':checked')) {
            $('.legimi-checkbox').removeClass('d-none');
            $('#b-register-form-agree-4').attr('required', true);
            $('#b-register-form-agree-5').attr('required', true);
            $('#b-register-form-agree-6').attr('required', true);
            $('#b-register-form-agree-7').attr('required', true);
        } else {
            $('.legimi-checkbox').addClass('d-none');
            $('#b-register-form-agree-4').attr('required', false);
            $('#b-register-form-agree-5').attr('required', false);
            $('#b-register-form-agree-6').attr('required', false);
            $('#b-register-form-agree-7').attr('required', false);
        }
        $('.legimi-checkbox').each(function () {
            let that = $(this);

            if(that.outerHeight() < that.find('.checkbox__content').outerHeight()) {
                that.addClass('checkbox--rollow');
            } else {
                that.removeClass('checkbox--rollow');
            }
        });
    });

    $('#b-register-form-agree-all').on('change', function () {
        if ($(this).is(':checked')) {
            $('.form-agree').find('input[type="checkbox"]').each(function () {
                if ($(this).is(':visible')) {
                    $(this).prop('checked', true);
                }
            });
        } else {
            $('.form-agree').find('input[type="checkbox"]').each(function () {
                if ($(this).is(':visible')) {
                    $(this).prop('checked', false);
                }
            });
        }
    });

    $('.slider').each(function () {
        const slidesToShow = $(this).data('slides-to-show');
        const arrows = $(this).data('arrows');
        const dots = $(this).data('dots');

        $(this).slick({
            slidesToShow: slidesToShow,
            arrows: arrows,
            dots: dots,
            draggable: $(this).closest('#workpage').length === 0
        });
    });

    $('div[data-news="true"]').each(function () {
        const self = this;
        const url = '/category/news';
        let data = {
            category: $(self).data('categoryid'),
        };

        if ($('#workpage').length === 0) {
            data.lang = lang;
        }

        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const news = response.responseJSON;

                $(self).find('.news-item').each(function (key) {
                    if (news[key]) {
                        $($(this).find('.photo')).css('background-image', `url(${news[key].image})`);
                        $($(this).find('.details h5')).text(news[key].title);
                        $($(this).find('.details h4')).text(news[key].lead);
                        $(this).removeClass('d-none');

                        $(this).removeClass('loading-skeleton');
                    }
                });
            }
        });
    });

    $('.contact-section .btn-submit').on('click', function (e) {
        e.preventDefault();

        const form = $(this).closest('form');
        const formData = form.serializeArray();
        const agree = formData.find(function (item) { return item.name === 'agree' });

        if (agree) {
            agree.value = $('input[name="agree"]').is(':checked')
        } else {
            formData.push({
                name: 'agree',
                value: $('input[name="agree"]').is(':checked')
            });
        }

        if (checkFormValidation(formData)) {
            formData.push(
                {
                    name: 'subject',
                    value: `Kontakt ze strony: ${window.location.href}`
                },
                {
                    name: 'sendTo',
                    value: form.attr('data-email')
                });

            $.ajax({
                url: form.attr('action'),
                type: 'POST',
                data: formData,
                complete: function (response) {
                    if (response.responseJSON.status === 'success') {
                        $(form).prepend('<div class="message-was-sent">Dziękujemy, wiadomość została wysłana.</div>');
                    } else {
                        $(form).prepend('<div class="message-was-not-sent">Przepraszamy. wiadomość nie została wysłana</div>');
                    }

                    formData.forEach(function (field) {
                        if ($(`form [name="${field.name}"]`)) {
                            $(`form [name="${field.name}"]`).removeClass('is-valid');
                            $(`form [name="${field.name}"]`).removeClass('is-invalid');

                            if (response.responseJSON.status === 'success') {
                                if ($(`form [name="${field.name}"]`).attr('type') === 'checkbox') {
                                    $(`form [name="${field.name}"]`).prop('checked', false);
                                } else {
                                    $(`form [name="${field.name}"]`).val('');
                                }
                            }
                        }
                    });
                }
            });
        }
    });
});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}
